<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardBody v-if="isLoading">
            <MbnSpinner />
          </CCardBody>

          <CCardBody v-if="error">
            <MbnErrorMessage />
          </CCardBody>

          <BlockForm
            v-if="initialValues"
            :is-sending="isSending"
            :initial-values="initialValues"
            @formSubmit="onSubmit"
          />
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { GET_BLOCK, EDIT_BLOCK } from '@/store/action-types/blocks';
import BlockForm from './BlockForm';
import MbnSpinner from '@/components/Spinner';
import MbnErrorMessage from '@/components/ErrorMessage';

export default {
  name: 'BlockEdit',
  components: {
    BlockForm,
    MbnSpinner,
    MbnErrorMessage,
  },
  computed: {
    ...mapState('blocks', ['isLoading', 'isSending', 'error', 'block']),
    recordId() {
      return this.$route.params.id;
    },
    initialValues() {
      if (!this.block) {
        return null;
      }

      return {
        name: this.block.name,
        siteId: this.block.siteId,
        type: this.block.type,
      };
    },
  },
  created() {
    this.GET_BLOCK(this.recordId);
  },
  methods: {
    ...mapActions('blocks', [GET_BLOCK, EDIT_BLOCK]),
    onSubmit(formData) {
      this.EDIT_BLOCK({ id: this.recordId, formData })
        .then(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.success'),
            type: 'success',
            text: this.$t('notifications.updated'),
          });
          this.$router.push({ name: 'blocks' });
        })
        .catch(() => {
          this.$notify({
            group: 'main',
            title: this.$t('notifications.error'),
            type: 'error',
            text: this.$t('notifications.updating_record_error'),
          });
        });
    },
  },
};
</script>
